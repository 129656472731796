import { useState, useEffect } from 'react';
import axios from 'axios';
// import Slider1 from '../assets/images/genz.png'
import "./slider.scss"
import { Link } from 'react-router-dom';

const Slider = ()=>{
    
    const [event, setEvent] = useState({})

    useEffect(() => {       
        axios.get('https://admin.tobepay.com/api/v1/get/web/events')
          .then(response => {
            var data = response.data??[];
            if(data.length > 0)
                setEvent(data[0])
          })
          .catch(error => {
            console.error('There was a problem with the Axios request:', error);
          });
      }, []);
    return (
        <div className="slider-wrapper">
            <div className="slider-container">
                {/* <img src={Slider1} alt="" srcSet="" /> */}
                {Object.keys(event).length > 0 && <div className="booker">
                    <Link to={"/event/"+btoa(JSON.stringify(event))} key={event.id}>
                        <button type="submit"><span>Buy your</span><span className='bld'>TICKETS HERE</span></button>
                    </Link>
                </div>}
            </div>
        </div>
    )
}

export default Slider