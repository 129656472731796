import "./event.scss"
const Footer = ()=>{
    return (
        <div className="footer-wrapper">
            <div className="footer-container">
                <span>©2024</span>
                <span>All Copyrights Reserved</span>
                <span>GEN-Z Comedy</span>
            </div>
        </div>
    )
}

export default Footer