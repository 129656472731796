import Header from '../components/header';
import Footer from '../components/footer';
// import BgImage from "../assets/images/genz.png"
import MomoIcon from "../assets/images/momo_icon.png"
import CardIcon from "../assets/images/card_icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown   } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const SingleEvent = ()=>{
    const [data, setData] = useState({});
    const [active, setActive] = useState(0);
    const { id } = useParams();
    const [number, setNumber] = useState(1);
    const [phone, setPhone] = useState("");
    const payTicket = ()=>{
        var obj = {
            phone: phone,
            amount: 0,
            type: 6,
            lang: "en",
            event_category_id: active.id,
            number_of_tickets: number,
            charity: 0
        }
        axios.post('https://admin.tobepay.com/api/v1/initiate-web-payment', obj)
            .then(response => {
                toast.success(<CustomToast message="Payment initiated successfully, Enter Momo Pin or Dial *187*7*1" />, {
                    position: 'bottom-right',
                    theme: "colored",
                    autoClose: 5000,
                    draggable: true
                });
            })
            .catch(error => {
                toast.error(<CustomToast message={error?.response?.data?.message ?? 'An error occurred.'} />, {
                    position: 'bottom-right',
                    autoClose: false,
                    theme: "colored",
                    draggable: true
                });
                // Handle errors here
            });
    }
    const handleInputChange = (event) => {
        setNumber(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const getFormattedDate = (dateTimeString)=>{
        const dateTimeParts = dateTimeString.split(' ');
        const dateParts = dateTimeParts[0].split('-');
        const timeParts = dateTimeParts[1].split(':');
        const dateObject = new Date(parseInt(dateParts[0]),parseInt(dateParts[1]) - 1,parseInt(dateParts[2]),parseInt(timeParts[0]),parseInt(timeParts[1]),parseInt(timeParts[2]));
        const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(dateObject);
    }

    useEffect(() => {  
       var eve = (JSON.parse(atob(id)))
       eve.date_string = getFormattedDate(eve.date_time).split("at")[0]
       eve.time = getFormattedDate(eve.date_time).split("at")[1]  
       setActive(eve.categories?.[0])    
       setData(eve)}, []);
    return (
        <div className="single-wrapper">
            <Header/>
            <div className="single-details">
                {/* <div className="single-details-content-mobile">
                    <h2> <span className='bgg'>Event Details</span>
                    <div className="detail">Date: {data.date_string}</div>
                    <div className="detail">Gates open : {data.time}</div>
                    <div className="detail">Venue : Camp Kigali KCEV</div>
                    <div className="detail">Location: {data.location}</div>
                    </h2>
                </div>
                <div className="single-details-content">
                    <h2>Event Details</h2>
                    <div className="detail">Date: {data.date_string}</div>
                    <div className="detail">Gates open : {data.time}</div>
                    <div className="detail">Venue : Camp Kigali KCEV</div>
                    <div className="detail">Location: {data.location}</div>
                    
                </div> */}
            </div>
            <div className="single-bg-container">
                <div className="single-container">
                    <h2>Tickets</h2>
                    <div className="single-container-row">
                        <div className="ticket-holder f_2">
                            <div className="cat-container">
                            {data.categories?.map((cat) => (
                                <div key={cat.id} className={active == cat?"cat-item cat_active":"cat-item"}
                                onClick={()=>setActive(cat)}>
                                    <div className="cat-content">
                                        <div className="circled"><div className="inner-circled"></div></div>
                                        <div className="cat-col">
                                            <span>{cat.name}</span>
                                            <span>{cat.amount} FRW</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            <div className="nbr-container">
                                <span>Enter number of people</span>
                                <input type="number" value={number} onChange={handleInputChange}/>
                                <div className="inc-buttons">
                                    <div className="inc-button" onClick={()=>setNumber(number+1)}>
                                        <FontAwesomeIcon icon={faChevronUp } />
                                    </div>
                                    <div className="inc-button" onClick={()=>{if(number >1) setNumber(number-1)}}>
                                        <FontAwesomeIcon icon={faChevronDown } />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="ticket-holder">
                            <div className="pay-container">
                                <div className="pay-img">
                                    <img src={MomoIcon} alt="" srcSet="" />
                                    <img src={CardIcon} alt="" srcSet="" />
                                </div>
                                <div className="pay-input">
                                    <span>*727*1*8#</span>
                                    <br />
                                    <input type="number" placeholder='0788' value={phone} onChange={handlePhoneChange}/>
                                    <button type="submit" onClick={()=> payTicket()}><span>Buy</span><span className='bld'>TICKETS</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer/>
        </div>
    )
}

const CustomToast = ({ closeToast, message }) => {
    return (
      <div>
        <p>{message}!</p>
        {/* <button onClick={closeToast}>Close</button> */}
      </div>
    );
  };
export default SingleEvent