import Header from '../components/header';
import Slider from '../components/slider';
import About from '../components/about';
import Event from '../components/event';
import Galery from '../components/galery';
import Footer from '../components/footer';

const Home = ()=>{
    return (
        <div className="home-wrapper">
            <Header/>
            <Slider/>
            {/* <About/> */}
            <Event/>
            {/* <Galery/> */}
            <Footer/>
        </div>
    )
}

export default Home