import './header.scss'
import Logo from '../assets/images/logo_s.png'
import { Link } from 'react-router-dom';

const Header  = ()=> {
    return (
        <div className="header-wrapper">
            <div className="header-container">
                <div className="logo">
                    <img src={Logo} alt="" srcSet="" />
                </div>
                <ul className='menu'>
                    <Link className='hide' to="/"><li className='active'>Home</li></Link>
                    <Link className='hide'  to="/#about"><li>About us</li></Link>
                    <Link className='hide'  to="/#event"><li>Event</li></Link>
                    <Link className='hide'  to="/#news"><li>News</li></Link>
                    <Link className='hide'  to="/#galery"><li>Galery</li></Link>
                </ul>
            </div>
        </div>
    )
}

export default Header