import "./event.scss"
import Logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';


const Event = ()=>{
    const [events, setEevents] = useState([]);
    
    const formatDate = (date)=> {
        const dateObject = new Date(date);
        const day = dateObject.getDate();
        const month = dateObject.toLocaleString('en-US', { month: 'long' });
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = day % 100;
        const ordinalSuffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
        return `${day}${ordinalSuffix} ${month}`;

    }
    useEffect(() => {       
        axios.get('https://admin.tobepay.com/api/v1/get/web/events')
          .then(response => {
            var data = response.data;
            data.map((item)=>{
                item.date_at = formatDate(item.date_time)
                return item
            })
            setEevents(data);
          })
          .catch(error => {
            console.error('There was a problem with the Axios request:', error);
          });
      }, []);
    return (
        <div className="event-wrapper">
            <div className="event-container">
                    <h2>Event</h2>
                <div className="event-content">
                    {events.map((event) => 
                    <Link to={"/event/"+btoa(JSON.stringify(event))} key={event.id}>
                    <div className="event">
                        <div className={event.id === 6?"event-holder":"event-holder bg_d"}>
                            {/* <div className="title">
                                <img src={Logo} alt=""/>
                                <h2><span className="yellow">{event.date_at}</span><br/><span className="white">2024</span></h2>
                            </div> */}
                        </div>
                        <div className="bottom">
                            <div className="date-bottom">
                                <h2>{event.date_at.toUpperCase()}</h2>
                            </div>
                            <div className="text-bottom">
                                <h2>{event.name.toUpperCase()}</h2>
                            </div>
                        </div>
                    </div>
                    </Link>
                    )}
                    <div className="spacer-h"></div>
                </div>
            </div>
        </div>
    )
}

export default Event