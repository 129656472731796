import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'; 
import SingleEvent from './pages/single_event';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>} /> 
          <Route exact path='/event/:id' element={<SingleEvent/>} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;

